.lazyload-form {
    overflow: hidden;
    margin-bottom: 20px;
}

.chargement {
    .active & {
        font-size: 0;
        margin-bottom: 20px;

        > span {
            width: 12px;
            height: 12px;
            background-color: $color_brand_primary;
            border-radius: 100%;
            position: relative;
            -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            display: inline-block;
            margin-right: 5px;
        }

        .bounce-1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
        }

        .bounce-2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
        }
    }
}
  
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}