.view-argumentaires {
    background: $color_brand_primary;
}

.slider_txt {
    h2 {
        font-family: $font-nav;
        font-size: 2em;
        text-transform: none;
    }
}
