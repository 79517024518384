body {
    color: $color_brand_primary;
    font-size: $font-size-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-text-bold;
}

label {
    a {
        text-decoration: underline;
    }

    strong {
        font-family: $font-text-bold;
        font-size: 1.3em;
    }

    em {
        display: block;
        font-size: .9em;
        font-style: normal;
        line-height: 1.4em;
        padding-left: 35px;
    }
}
