//blocs offres
.wrap-offre {
    .box-offre {
        background-image: none;

        &:hover {
            @include mdlg {
                background-color: $color_brand_primary;

                h2 {
                    border-bottom: 1px solid $color_brand_primary;
                    color: $color_brand_secondary;
                }

                a {
                    color: $white;
                }

                .price {
                    background: none;
                    color: $color_brand_secondary;

                    span {
                        color: $white;
                    }

                    strong {
                        span {
                            color: $color_brand_secondary;
                        }
                    }
                }
            }
        }

        a {
            &:after {
                background: $color_brand_secondary;
                border-radius: 5px;
                bottom: 10px;
                color: $white;
                content: '\E00D';
                display: block;
                font-family: icons;
                font-size: 14px;
                height: 30px;
                line-height: 30px;
                position: absolute;
                right: 10px;
                text-align: center;
                width: 30px;
            }
        }

        h2 {
            color: $color_brand_primary;
            font-size: 3rem;
        }

        .price {
            min-height: 140px;
        }

        .img-responsive {
            border-radius: 10px;
        }

        &.premium {
            background-image: none;

            &:hover {
                @include mdlg {
                    background-image: none;

                    a {
                        color: $white;
                    }

                    h2 {
                        border-bottom: 1px solid $color_brand_primary;
                        color: $white;
                    }

                    .price {
                        background: none;

                        span {
                            color: $white;
                        }
                    }

                    .img-responsive {
                        box-shadow: 0 0 1px #ccc;
                    }
                }
            }

            h2 {
                color: $color_brand_secondary;
            }

            .price {
                background: none;
                color: $white;

                span {
                    color: $white;
                }
            }
        }
    }
}

.surtitre {
    display: none;
}

.price {
    background: none;
    border-radius: 0;
    color: $color_brand_secondary;
    font-family: $font-nav;

    span {
        color: $color_brand_primary;
        font-family: $font-nav;
        font-size: 2rem;
        line-height: 2rem;
    }

    strong {
        font-family: $font-nav;
        font-size: 8rem;
        line-height: 8rem;

        span {
            color: $color_brand_secondary;
            font-size: 4rem;
            line-height: 5rem;
        }
    }
}
