.call_center {
    margin: auto;
    max-width: $max-width-lg;
    position: relative;

    @include xssm {
        min-width: 290px;
    }
}

.box_call_center {
    color: $color_brand_primary;
    position: absolute;
    right: 10px;
    top: 29px;
    z-index: 10;

    @include xssm {
        top: 10px;
    }

    &:hover {
        text-decoration: none;
    }

    h2 {
        font-size: 1.5em;
        margin: 0 0 5px;
    }

    p {
        border: 0;
        font-size: 14px;
        margin: 0;
        padding: 0;
    }

    em {
        font-size: .75em;
    }

    .cm-box {
        background: $white;
        box-shadow: 0 0 3px;
        display: none;
        padding: 10px;
        position: absolute;
        right: 0;
        top: 45px;
        width: 250px;
        z-index: 100;

        h2 {
            font-size: 20px;
            margin-top: 10px;
        }
    }

    .cm-call {
        cursor: pointer;
        display: inline-block;

        &:hover {
            background: lighten($color_brand_primary, 15%);
        }

        .node-type-formulaire-perso &,
        .node-type-webform & {
            box-shadow: none;
        }
    }

    .node-type-formulaire-perso &,
    .node-type-webform & {
        color: $white;
    }
}
