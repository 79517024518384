#cm-menu-formule {
    @include text-nav;
    clear: both;

    ul {
        height: 40px;
        line-height: 40px;
        margin-right: 40px;
        padding: 0;

        @include xssm {
            margin-left: 0;
            margin-right: 0;
        }
    }

    li {
        display: inline-block;
        vertical-align: middle;

        a {
            color: $color_brand_primary;
            display: block;
            font-size: .9em;
            height: 40px;
            line-height: 40px;
            margin: 0 1px;
            padding: 3px 15px 0;
            text-transform: uppercase;

            @include xs {
                font-size: 1.1em;
            }

            &:hover {
                background: none;
                color: $color_brand_secondary;
                text-decoration: none;
            }
        }

        .active {
            color: $gray_darker;
            font-weight: bold;
        }
    }

    .container {
        padding: 0;

        @include md {
            width: auto;
        }
    }

    .dropdown-menu {
        border-radius: 0;
        position: absolute;
        z-index: 1;
    }

    .dropdown {
        position: relative;
    }
}
