#footer {
    background: $gray_darker;
    color: $gray_light;
    padding: 30px;

    .col-md-3 {
        text-align: right;

        @include xssm {
            text-align: center;
        }
    }

    a {
        color: $white;
    }

    .logo {
        fill: $white;
        width: 150px;
    }
}
