.box-promos {
    @include xssm {
        margin: 0 10px;
        margin-bottom: 30px;
    }

    .view-content {
        box-shadow: 0 0 1px $gray_light;
    }

    .slick-slider {
        overflow: visible;
    }

    img {
        box-shadow: 0 0 1px $gray_light;
        width: 100%;
    }

    .surtitre {
        @include xssm {
            margin: 0 10px;
        }
    }

    .wrap-offre {
        padding: 0;

        @include xssm {
            max-width: none;
        }
    }
}
