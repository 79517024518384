form {
    .form-checkboxes,
    .form-radios {
        label {
            font-family: $font-text;
        }
    }
}

.form-group {
    margin: 8px 0 0;

    label {
        &.control-label {
            color: $gray-dark;
            font-weight: 300;
        }
    }

    &.label-static,
    &.label-floating.is-focused,
    &.label-floating:not(.is-empty) {
        label.control-label {
            top: -25px;
        }
    }
}

.form-control[readonly],
.form-group .form-control[readonly] {
    background: $gray_lighter;
    border-bottom: 1px solid #d2d2d2;
}

//fieldset
fieldset {
    &.panel-default {
        border: 0;
        box-shadow: none;
        margin: 0;

        fieldset {
            margin-bottom: 30px;

            legend {
                a {
                    font-size: 21px;
                }
            }
        }

        .panel-heading {
            background: none;
            border: 0;
            font-family: $font-title;
            font-weight: bold;
            margin-bottom: 10px;
            top: 10px;

            span {
                font-size: 2.5rem;
            }
        }

        .panel-body {
            padding: 15px 15px 0;

            @include xssm {
                padding: 10px 5px 0;
            }
        }
    }

    &.hide-tabs {
        .nav-tabs {
            display: none;
        }
    }
}

//legend
.panel {
    &,
    &.panel-default {
        & > .panel-heading {
            background: none;
            padding: 0;
        }
    }
}

//bouton radio
.radio {
    margin-bottom: 5px;

    input[type='radio'] {
        height: auto;
        left: -13px;
        position: relative;
        width: auto;
    }
}

.webform-client-form {
    .webform-component-radios {
        > .control-label {
            font-family: $font-nav;
            font-size: 21px;
            margin: 0;
        }

        .form-radios {
            margin-left: 30px;

            @include xs {
                display: block;
            }
        }

        .radio {
            margin: 0;

            .control-label {
                font-size: $font-size-base;
                line-height: 19px;
                margin: 8px 0 0;
            }
        }
    }
}

//checkbox
.checkbox {
    margin-bottom: 5px;
    margin-left: 15px;

    input[type='checkbox'] {
        height: auto;
        width: auto;
    }

    .checkbox-material {
        display: inline-block;
    }
}

.form-checkboxes {
    .check {
        margin-right: 15px;
    }

    .checkbox {
        .control-label {
            margin-top: 0;
        }
    }
}

//select
.webform-component-select,
.form-type-select {
    background: url('../../bootstrapMarketing/img/arrow-down.svg') bottom 25px right 8px no-repeat;
    background-size: 12px;
    position: relative;
}

.form-select {
    @include appearance(none);
}

//select images
.webform-component-select-images {
    .control-label {
        padding-left: 0;
    }

    .form-radios {
        @include flex;
        @include flex-wrap(wrap);
    }

    .radio {
        margin: 0 1%;
        width: 48%;

        input[type='radio'] {
            ~ .img-responsive {
                margin: 5px;
            }

            &:checked {
                ~ .img-responsive {
                    border: 5px solid $color_brand_primary;
                    margin: 0;
                }
            }
        }
    }
}

//date
.cm-date {
    @include make-row;

    label {
        &.control-label {
            @include make-md-column(12);
            margin-top: 0;
            padding-left: 0;
        }
    }

    .webform-calendar,
    .form-item {
        @include make-md-column(3);
        margin-right: 15px;
        margin-top: 7px;
        padding: 0;
    }

    .webform-calendar {
        margin-top: 17px;
        max-width: 18px;
        padding: 0;
    }
}

//heure
.cm-time {
    .webform-container-inline {
        select {
            display: inline;
            width: 30%;
        }

        .form-item {
            display: inline;
        }
    }
}

//adresse belge - libraire
.webform-component--libraire,
.cm-adresse-belge {
    &.panel-default {
        .panel-heading {
            margin-bottom: 0;

            span {
                color: $color_brand_primary;
                font-size: 1.8rem;
            }
        }

        .panel-body {
            padding-left: 0;
            padding-top: 0;
        }
    }
}

.webform-component-belgian-address {
    padding: 0 5px 0 0;
}

//radio images
.cm-radio-image {
    img {
        border: 3px solid transparent;
    }

    input[type='radio'] {
        &:checked ~ img {
            border: 3px solid $color_brand_secondary;
        }
    }

    .form-radios {
        @include make-row;
    }

    .radio {
        @include make-sm-column(6);
        margin: 0;
    }
}

//description offre
.field-name-field-visuel-grand {
    margin: 0 0 15px;
    padding: 0 0 10px;

    img {
        display: block;
        margin: auto;
    }
}

//fichier
.webform-component-file {
    margin: 10px 0;

    input[type=file] {
        line-height: 1;
        opacity: 1;
        position: static;
    }

    &.form-group {
        label.control-label {
            // background: $gray_lighter;
            line-height: 30px;
            margin: 0;
            // padding: 3px 10px;
            // width: 73%;
        }
    }

    .form-control {
        &.form-file {
            // bottom: auto;
            // height: 34px;
            border: 0;
            margin: 0;
            width: 80%;
        }
    }

    .form-submit {
        background: $gray_light;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 150;
    }

    .description {
        text-transform: none;

        a {
            text-decoration: underline;

            .glyphicon-question-sign {
                display: none;
            }
        }
    }
}

.popover {
    background: $white;

    .popover-title {
        color: $gray_darker;
    }
}

//input type number
input[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }
}

//règles backend
.small {
    &.form-group {
        label {
            &.control-label {
                font-size: 12px;
                line-height: 13px;

                strong {
                    font-size: 12px;
                    line-height: 13px;
                }
            }
        }
    }
}

.cm-label-bold {
    label {
        &.control-label {
            font-family: ralewaybold;
        }
    }
}

#edit-submitted-adresse-facturation-adresse-francaise {
    .panel-body {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        grid-template-rows: auto;

        &::before {
            display: none;
        }

        .form-item {
            width: 100%;
        }

        .bg-success {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
            width: 100%;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-code-postal {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-ville {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-numero-rue {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-nom-rue {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-facturation-adresse-francaise-override-validation {
            display: inline-block;
            grid-row-start: 6;
            grid-column-end: 5;
            grid-column-start: 1;

            .control-label {
                font-size: 16px;
                margin-top: 8px;
            }
        }
    }
}

#edit-submitted-adresse-livraison-adresse-francaise {
    .panel-body {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr;
        grid-template-rows: auto;

        &::before {
            display: none;
        }

        .form-item {
            width: 100%;
        }

        .bg-success {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 1;
            grid-row-end: 2;
            width: 100%;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-code-postal {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: auto;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-override-validation {
            display: inline-block;
            grid-row-start: 6;
            grid-column-end: 5;
            grid-column-start: 1;

            .control-label {
                font-size: 16px;
                margin-top: 8px;
            }
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-ville {
            grid-column-start: 2;
            grid-column-end: 5;
            grid-row-start: auto;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-numero-rue {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-nom-rue {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-boite {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-cedex {
            grid-column-start: 3;
            grid-column-end: 5;
        }

        .form-item-submitted-adresse-livraison-adresse-francaise-complement-adresse {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
        }
    }
}