// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

body {
  background-color: $body-bg;
  &.inverse {
    background: #333333;
    &, .form-control {
      color: $mdb-text-color-light;
    }
    .modal,
    .panel-default,
    .card {
      &,
      .form-control {
        background-color: initial;
        color: initial;
      }
    }

  }
}


body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
  font-family: $font-family-sans-serif;
  font-weight: 300;
}

h5, h6{
  font-weight: 400;
}

a, a:hover, a:focus {
  color: $link-color;

  & .material-icons {
    vertical-align: middle;
  }
}

@import '_form';
@import '_welljumbo';
@import '_buttons';
@import '_checkboxes';
@import '_togglebutton';
@import '_radios';
@import '_inputs';

legend {
  border-bottom: 0;
}

@import '_lists';
@import '_navbar';

.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  .divider {
    background-color: rgba(0, 0, 0, .12);
  }
  li {
    overflow: hidden;
    position: relative;
    a:hover {
      background-color: transparent;
      color: $brand-primary;
    }
  }
}

@import '_alerts';
@import '_progress';
@import '_typography';
@import '_tabs';
@import '_popups';
@import '_cards';
@import '_dialogs';
@import '_panels';
@import '_dividers';

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}


@import '_themes';

// External plugins
@import '_plugins';
