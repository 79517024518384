// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

// Support for SnackbarJS plugin
// https://github.com/FezVrasta/snackbarjs

.snackbar {
    // Style
    background-color: #323232;
    color: $mdb-text-color-light;
    font-size: 14px;
    border-radius: $border-radius-base;
    @include shadow-z-1;

    // Animation
    height: 0;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
    transform: translateY(200%);
}

.snackbar.snackbar-opened {
    // Style
    padding: 14px 15px;
    margin-bottom: 20px;

    // Animation
    height: auto;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in, height 0s linear 0.2s, height 0s linear 0.2s;
    transform: none;
}

// Variations
.snackbar.toast {
    border-radius: 200px;
}
