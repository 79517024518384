.overlay {
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .4s ease;
    visibility: hidden;
    width: 100%;
    z-index: 100000;

    &.is-animated {
        display: block;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }
}

.overlay__bkg {
    background: rgba(0, 0, 0, .6);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.overlay__wrapper {
    background: #fff;
    left: 10px;
    position: absolute;
    top: 10px;
    bottom: 10px;
    transform: translate(0%, 0%);
    transition: all .3s ease;
    width: calc(100% - 20px);
    z-index: 1;
    -webkit-overflow-scrolling: touch;

    @include mdlg {
        max-height: 600px;
        max-width: 900px;
        height: 80%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -75%);
        width: 80%;
    }

}

.overlay__title {
    line-height: 50px;
    padding: 0;
}

.overlay__close {
    background: $color_brand_primary;
    color: #FFF;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;

    &:hover {
        background: lighten($color_brand_secondary, 10%);
        color: #fff;
    }

    .icon {
        display: block;
        height: 50px;
        position: relative;
        width: 50px;

        &:before {
            content: "x";
            font-family: arial;
            font-size: 20px;
            height: 50px;
            left: 0;
            line-height: 50px;
            position: absolute;
            text-align: center;
            top: 0;
            width: 50px;
        }
    }
}

.overlay__content {
    bottom: 0;
    overflow: auto;
    padding: 10px;
    position: absolute;
    top: 50px;
    width: 100%;
}

.is-visible .overlay__wrapper {
    @include mdlg {
        transform: translate(-50%, -50%);
    }

}

.is-hide .overlay__wrapper {
    @include mdlg {
        transform: translate(-50%, -25%);
    }
}

// DIRECT DEBUG

.overlay--direct-debug {

    .overlay__bkg {
        background: rgba(255, 0, 0, .3);
    }

    .overlay__title {
        background: #F00;
        color: #fff;
        font-size: 21px;
        text-align: center;
        text-transform: uppercase;
    }

    .direct-item-id {
        margin-bottom: 5px;
        margin-top: 30px;
    }

    .direct-item-label {
        font-weight: bold;
    }
}

// OLD BROWSER

.overlay--old-browser {
    .overlay__title {
        background: $color_brand_primary;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
    }

    .overlay__content {
        background: #fff;
        text-align: center;
    }

    .overlay__header {
        font-size: 20px;
        font-weight: bold;
        padding: 40px 0 0;
    }

    .overlay__list {
        font-size: 0;
        list-style-type: none;
        margin: 40px 0;
        padding: 0;
    }

    .overlay__item {
        display: inline-block;
        font-size: 16px;
        margin: 10px;
        width: 140px;
    }

    .overlay__thumb {
        height: 100px;
    }

    .overlay__link {
        display: block;
    }
}
