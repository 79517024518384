.navbar {
    &.navbar-inverse {
        background: #fff;
        border-bottom: 1px solid $gray-light;
        border-top: 0;
        //background: -moz-linear-gradient(left, #11345e 0%, #1d569b 50%, #11345e 99%);
        //background: -webkit-linear-gradient(left, #11345e 0%, #1d569b 50%, #11345e 99%);
        //background: linear-gradient(to right, #11345e 0%, #1d569b 50%, #11345e 99%);
        //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#11345e', endColorstr='#11345e', GradientType=1);
    }

    h1 {
        color: $color_brand_primary;
        font-family: $font-nav;
        //display: none;
    }
}

.boutique-title {
    margin: 20px auto;
    text-align: center;

    img {
        height: 35px;
    }
}

.cm-main-title {
    display: none;
    margin-bottom: 15px;

    .container {
        @include md {
            width: auto;
        }
    }
}
