.btn,
.input-group-btn .btn,
.btn-group,
.btn-group-vertical {

    fieldset[disabled][disabled] &,
    &.disabled,
    &:disabled,
    &[disabled][disabled] {
        border: 1px solid $gray_light;
        color: $gray !important;
        padding: 5px 20px;
    }
}

button,
.btn {
    background: $color_brand_secondary;
    border: 1px solid $color_brand_secondary;
    border-radius: 0;
    display: inline-block;
    font-family: $font-nav;
    margin: 10px 0;
    padding: 5px 20px;
    position: relative;
    text-transform: uppercase;

    &:not(.swg-button):hover {
        background: lighten($color_brand_secondary, 10%) !important;
    }

    a {
        color: $color_brand_primary;
        font-weight: bold;
        text-decoration: none;
    }

    &.btn-facebook {
        background: $facebook;
        color: $white;

        &:before {
            color: $white;
            content: '\E010';
            font-family: icons;
            font-size: 17px;
            margin-right: 10px;
            position: relative;
            top: 2px;
        }

        &:hover {
            background: lighten($color_brand_primary, 10%) !important;
        }
    }
}

.btn_more_abo {
    clear: both;
    display: block;
    margin-top: 15px;
    min-height: 50px;
    padding: 10px 0;
    text-align: center;

    @include xssm {
        margin: 15px 10px 0;
    }

    button {
        @include text-regular;
        background: $color_brand_secondary;
        border: 0;
        box-shadow: 0 0 2px $gray_light;
        color: $black;
        display: inline-block;
        margin: 0 auto;
        padding: 10px 20px;

        &:after {
            color: $white;
            content: '\E00D';
            display: inline-block;
            font-family: icons;
            font-size: 17px;
            padding-left: 10px;
            position: relative;
            top: 1px;
        }

        &:hover {
            background-color: lighten($color_brand_secondary, 10%);
            text-decoration: none;
        }
    }

    .btn_active {
        background: $color_brand_secondary;

        &:after {
            color: $white;
            content: '\E00C';
            display: inline-block;
            font-family: icons;
            padding-left: 10px;
            position: relative;
            top: 2px;
        }
    }
}