#cm-main-menu {
    @include text-nav($font-size: 1.5em, $line-height: 1.5em);
    background-color: $white;
    text-align: center;

    @include xs {
        font-size: 1.1em;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    a {
        color: $color_brand_primary;

        &:hover {
            background: none;
            color: $color_brand_secondary;
            text-decoration: none;
        }
    }

    li {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        list-style: none;
    }

    //si on est pas dans le kiosque, le premier lien est actif
    body:not(.node-type-kiosque) & {
        li {
            &:first-child {
                a {
                    color: $color_brand_secondary;

                    &:hover {
                        color: $color_brand_primary;
                    }
                }
            }
        }
    }

    .node-type-kiosque & {
        li {
            &:last-child {
                a {
                    color: $color_brand_secondary;

                    &:hover {
                        color: $color_brand_primary;
                    }
                }
            }
        }
    }

    .container {
        padding: 0;
    }
}
