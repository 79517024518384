//Page statique
.page-description {
    padding: 15px;
}

.taxonomy-term-description {
    @include xssm {
        padding: 10px;
    }
}

.field-name-field-background-image {
    img {
        height: auto;
        width: 100%;
    }
}

.parallax-window {
    background: transparent;
    min-height: 200px;
}

.page-payment-cardrenew,
.node-type-page {
    article {
        background: $white;
        margin: 30px;
        padding: 30px;

        @include lg {
            margin: 30px auto;
            max-width: $max-width-lg;
        }
    }
}

.page-payment-cardrenew {
    .form-group {
        display: block;

        label {
            color: $gray_darker;
        }
    }

    .form-control {
        display: block;
    }
}

//page static (cgv, cgu)
.node-page {
    color: $black;
    font-size: 16px;
    
    p {
        margin-bottom: 15px;
        max-width: 800px;
    }
    
    a {
        font-weight: bold;
        text-decoration: underline;
    }
}
