.node-type-formulaire-perso,
.node-type-webform {
    .cm-title {
        .btn {
            display: none;
        }
    }
}

.node-webform {
    margin: 0;
}

.field-name-field-descriptif-long {
    font-size: $font-size-base;
    padding: 0 15px;
}

//formulaire personnalisé
.field-name-field-sous-titre {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.field-name-field-intro-droite {
    padding: 10px;
}

//fin
.form-control {
    border-radius: 0;
    box-shadow: none;
}

legend.panel-heading {
    position: relative;
    top: 25px;
}

.field-collection-container {
    display: none;

    .landing & {
        display: block;
    }
}

.cm-form-left,
.cm-form-right {
    background: $white;
    box-shadow: 0 0 1px $gray_light;
    float: left;
    margin: 30px;
    width: calc(50% - 45px);

    @include xssm {
        float: none;
        margin: 15px;
        width: auto;
    }
}

.cm-form-left {
    margin-right: 0;
    max-width: $max-width-lg;
    padding: 15px;

    @include xssm {
        margin: 0 10px 10px;
        @include flex-direction(column-reverse);
    }

    p {
        margin-bottom: 20px;
    }

    li {
        margin: 5px 0;
    }

    .field-name-field-descriptif-long {
        @include xssm {
            width: 100%;
        }
    }

    .price {
        @include flex-order(2);
        background: none;
        border-radius: 0;
        margin: auto;
        width: 50%;

        @include xssm {
            margin: 0 15px;
            width: 100%;
        }

        strong {
            color: $color_brand_secondary;
        }
    }
}

.cm-form-content {
    padding: 30px;

    @include xs {
        min-width: 0;
        padding: 10px;
        width: auto;
    }
}

.form-actions {
    @include flex;
    @include flex-wrap(wrap);
}

.webform-previous {
    &.btn {
        background: $gray_light;
        border: 1px solid $gray_light;
        margin-right: 15px;
        padding: 7px;
        width: auto;

        &:hover {
            background: $gray_lighter;
            border: 1px solid $gray_lighter;
        }
    }
}

.webform-submit,
.webform-next {
    .form-actions &.btn {
        color: $color_brand_primary;
        font-size: 1.5em;
        font-weight: bold;

        &:hover {
            background: $gray-dark;
        }
    }
}

//alertes
.messages.warning {
    padding: 15px;
}

.webform-confirmation {
    border-top: 0;
    padding: 15px;
}

.lien-retour {
    font-size: 2em;
    margin: 30px;
}

// barre d'étapes
.cm-progressbar-page {
    border-top: 1px solid $gray_lighter;
    color: $gray_light;
    display: block;
    font-family: $font-nav;
    font-size: 4.2rem;
    font-weight: bold;
    padding: 15px;

    @include xs {
        font-size: 2.5rem;
        padding: 5px 10%;
    }

    &.completed,
    &.current {
        display: none;
    }

    &.current {
        color: $gray_dark;
    }
}

.cm-progressbar {
    &:first-child {
        width: auto;

        .cm-progressbar-page {
            display: none;

            &.current {
                background: $gray_lighter;
                border-top: 1px solid $gray_lighter;
                color: $color_brand_primary;
                display: block;
                font-family: $font-nav;

                @include xs {
                    font-size: 2.5rem;
                }

                @include sm {
                    font-size: 4rem;
                }
            }
        }
    }
}

.cm-progressbar-number,
.cm-progressbar-page-number {
    display: none;
}

.cm-adresse-francaise {
    overflow: hidden;
}

.password-container {
    position: relative;

    .form-control-feedback {
        top: 36px;
    }

    .glyphicon-eye-open,
    .glyphicon-eye-close {
        &::before {
            font-family: "icons";
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            text-decoration: none;
            text-transform: none;
        }
    }

    .glyphicon-eye-open {
        &::before {
            content: "\E020";
        }
    }

    .glyphicon-eye-close {
        &::before {
            content: "\E01F";
        }
    }
}

.password-verification {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;

    li {
        align-items: center;
        background-color: rgba($gray, .4);
        border: 1px solid $gray;
        border-radius: 20px;
        color: $gray;
        display: flex;
        font-size: 0.8em;
        margin-bottom: space(.5);
        margin-right: space(.5);
        padding: space(.5);

        &:last-of-type {
            margin-right: 0;
        }
    }

    r-icon {
        color: $gray;
        margin-right: space(.5);
    }

    .password-error {
        background-color: rgba(red, .4);

        r-icon {
            color: red;
        }
    }

    .password-validate {
        background-color: rgba(green, .4);

        r-icon {
            color: green;
        }
    }
}