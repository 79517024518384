.call_center {
    @include xssm {
        border: 0;
        margin-top: 0;
        min-width: 320px;
    }

    @include xs {
        border: 0;
    }

    a {
        text-decoration: underline;
    }

    .node-type-formulaire-perso,
    .node-type-webform {
        .landing & {
            background: rgba($black, .5);
            padding-top: 15px;
            position: absolute;
            right: 15px;
            top: 15px;

            a {
                color: $color_brand_secondary;
            }
        }
    }
}

.box_call_center {
    border-left: 0;
    padding-left: 0;

    .cm-call {
        background-size: 70%;
        height: 20px;
        width: 20px;

        &:before {
            background: $color_brand_primary;
            color: $white;
            content: '\E00F';
            font-family: icons;
            font-size: 14px;
            padding: 5px;
        }

        &:hover {
            background-size: 70%;
        }
    }

    .cm-box {
        color: $gray_darker;

        h2 {
            font-size: $font-size-h4;
            margin-top: 10px;
        }
    }
}
