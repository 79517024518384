#mini-panel-footer {
    background: #fff;
    border-top: 1px solid $gray-light;
    padding: 30px;
}

.gr-footer-menu {
    background: $white;
    color: $color_brand_primary;
    border-top: 3px solid $color_brand_secondary;
    padding: 15px 0;
    font-size: 15px;

    p {
        margin: 0;
    }
}