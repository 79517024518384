// sass-lint:disable-all

@charset "UTF-8";

@font-face {
	font-family: 'icons';
    font-weight: normal;
    font-style: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapMarketing/fonts/icons/icons.woff') format('woff'),
         url('/sites/all/themes/custom/boutique/bootstrapMarketing/fonts/icons/icons.woff2') format('woff2');
}

.icon:before, r-icon {
	font-family: "icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none;
}


.icon-01-arrow-bottom:before, .r--01-arrow-bottom::before {
	content: "\E001";
}

.icon-02-arrow-down:before, .r--02-arrow-down::before {
	content: "\E002";
}

.icon-03-arrow-left:before, .r--03-arrow-left::before {
	content: "\E003";
}

.icon-04-arrow-right:before, .r--04-arrow-right::before {
	content: "\E004";
}

.icon-05-checked:before, .r--05-checked::before {
	content: "\E005";
}

.icon-06-fleche_droite:before, .r--06-fleche_droite::before {
	content: "\E006";
}

.icon-07-fleche_gauche:before, .r--07-fleche_gauche::before {
	content: "\E007";
}

.icon-08-lock:before, .r--08-lock::before {
	content: "\E008";
}

.icon-09-phone:before, .r--09-phone::before {
	content: "\E009";
}

.icon-10-ena-left:before, .r--10-ena-left::before {
	content: "\E00A";
}

.icon-11-ena-right:before, .r--11-ena-right::before {
	content: "\E00B";
}

.icon-12-angle-arrow-down:before, .r--12-angle-arrow-down::before {
	content: "\E00C";
}

.icon-13-angle-arrow-right:before, .r--13-angle-arrow-right::before {
	content: "\E00D";
}

.icon-14-envelope:before, .r--14-envelope::before {
	content: "\E00E";
}

.icon-15-info:before, .r--15-info::before {
	content: "\E00F";
}

.icon-16-facebook:before, .r--16-facebook::before {
	content: "\E010";
}

.icon-17-home:before, .r--17-home::before {
	content: "\E011";
}

.icon-18-user:before, .r--18-user::before {
	content: "\E012";
}

.icon-19-error:before, .r--19-error::before {
	content: "\E013";
}

.icon-20-notif-info:before, .r--20-notif-info::before {
	content: "\E014";
}

.icon-21-warning:before, .r--21-warning::before {
	content: "\E015";
}

.icon-22-cross:before, .r--22-cross::before {
	content: "\E016";
}

.icon-23-upload-button:before, .r--23-upload-button::before {
	content: "\E017";
}

.icon-24-newsletter:before, .r--24-newsletter::before {
	content: "\E018";
}

.icon-25-user-plain:before, .r--25-user-plain::before {
	content: "\E019";
}

.icon-26-check:before, .r--26-check::before {
	content: "\E01A";
}

.icon-27-arrow-bottom-light:before, .r--27-arrow-bottom-light::before {
	content: "\E01B";
}

.icon-28-payment:before, .r--28-payment::before {
	content: "\E01C";
}

.icon-29-handshake:before, .r--29-handshake::before {
	content: "\E01D";
}

.icon-30-search:before, .r--30-search::before {
	content: "\E01E";
}

.icon-31-eye-closed:before, .r--31-eye-closed::before {
	content: "\E01F";
}

.icon-32-eye-open:before, .r--32-eye-open::before {
	content: "\E020";
}

.icon-33-check-square:before, .r--33-check-square::before {
	content: "\E021";
}
