// General
// --------------------------

#cm-main-menu ul,
#cm-menu-formule ul,
.horizon-swiper {
    box-sizing: border-box;
    position: relative;

    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    a,
    button {
        &:focus {
            outline: none;
        }
    }

    &.mouse-drag,
    &.mouse-drag * {
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    // Wrapper
    .horizon-outer {
        overflow: hidden;
    }

    .horizon-inner {
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 0 20px 0;
        white-space: nowrap;
    }

    // Items
    li,
    .horizon-item {
        display: inline-block;
        position: relative;
        white-space: nowrap;
    }

    // Dots
    .horizon-dots {
        display: none;
        text-align: center;

        .horizon-dot {
            display: inline-block;
            margin: 0 3px;
        }
    }

    // Arrows
    .horizon-prev,
    .horizon-next {
        display: none;
    }

    &.initialized {
        .horizon-dots {
            display: block;
        }

        .horizon-prev,
        .horizon-next {
            display: block;
        }
    }

    &.first-item .horizon-prev,
    &.last-item .horizon-next {
        opacity: .2;
    }
}
