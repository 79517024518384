#node-1072 {
  .tab-payment-FREE {
    a {
      display: none;
    }
  }
}

.onglets-paiement,
.message-renew-card {

  .form-text,
  .form-control,
  .form-group .form-control {
    background-color: $white;
    border-radius: 4px;
    // border-top-width: 0;
    // border-left-width: 0;
    // border-right-width: 0;
    border: 1px solid lighten(#bdbdbd, 10%);

    &.ccInvalid,
    &.cvcInvalid,
    &.cardholderInvalid {
      border-color: red;
    }

    &.ccValid,
    &.cvcValid,
    &.cardholderValid {
      border-color: green;
    }
  }

  .webform-component-textfield {
    position: relative;

    &.validDate {
      display: inline-block;
      margin-right: 11px;
      width: calc(100% - 140px);

      @include smmdlg {
        width: calc(50% - 10px);
        display: inline-block;
      }

      &.monthyearInvalid {
        &>div {
          border-color: red;
        }
      }

      &.monthyearValid {
        &>div {
          border-color: green;
        }
      }

      &+.webform-component-textfield {
        display: inline-block;
        margin-right: 0;
        width: 120px;

        @include smmdlg {
          width: calc(50% - 11px);
        }
      }

      &>label {
        margin: 0;
        width: 100%;
      }

      div {
        background-clip: padding-box;
        background-color: $white;
        //border: 1px solid color(primary);
        //border-radius: 10px;
        color: color(gray, dark);
        display: block;
        line-height: 1.5;
        margin: 0;
        padding: 8px;
        width: 100%;

        select {
          display: inline-block;
          border: none;
          background: none;
        }

        label {
          display: inline-block;
          margin: 0;
        }
      }

      &>div {
        border: 1px solid lighten(#bdbdbd, 10%);
        border-radius: 4px;
        padding: 6px;
      }
    }

    &>img,
    &>svg {
      width: 40px;
      position: absolute;
      bottom: 14px;
      right: 16px;
      z-index: 2222222;
    }

    label.control-label {
      margin-top: 4px;
      position: relative !important;
      top: auto !important;
    }

    &.monthyearInvalid>div {
      &>div {
        border: 1px solid red;
      }
    }

    &.monthyearValid>div {
      &>div {
        border: 1px solid green;
      }
    }

    >div {
      background-clip: padding-box;
      background-color: $white;
      border: 1px solid lighten(#bdbdbd, 10%);
      border-radius: 0;
      color: black;

      select {
        display: inline-block;
        border: none;
        background: none;
      }

      label {
        display: inline-block;
        margin: 0;
      }

      &>svg,
      &>img {
        width: 40px;
        position: absolute;
        bottom: space(.5);
        right: space();
        z-index: 2222222;
      }
    }
  }

  #tab-payment-OGU,
  #tab-payment-OG,
  #tab-payment-OGR_FREE,
  #tab-payment-OGR {
    .validDate {
      display: inline-block;
      margin-right: 11px;
      width: calc(100% - 120px);

      @include smmdlg {
        width: calc(50% - 8px);
      }

      &.monthyearInvalid {
        &>div {
          border-color: red;
        }
      }

      &.monthyearValid {
        &>div {
          border-color: green;
        }
      }

      &+.webform-component-textfield {
        display: inline-block;
        margin-right: 0;
        width: 104px;

        @include smmdlg {
          width: calc(50% - 8px);
        }
      }

      &>label {
        margin: 0;
        width: 100%;
      }

      div {
        select {
          display: inline-block;
          border: none;
          background: none;
        }

        label {
          display: inline-block;
          margin: 0;
        }
      }
    }
  }
}

.validNumber {
  .creditCardVisa {
    opacity: 0;
  }

  .creditCardMaster {
    opacity: 0;
  }

  .ccInvalid {
    &~.creditCard {
      opacity: 1 !important;
    }

    &~.creditCardVisa {
      opacity: 0 !important;
    }

    &~.creditCardMaster {
      opacity: 0 !important;
    }

    &.ccMastercard {
      &~.creditCardMaster {
        opacity: 0.4 !important;
      }

      &~.creditCardVisa,
      &~.creditCard {
        opacity: 0 !important;
      }
    }

    &.ccVisa {
      &~.creditCardVisa {
        opacity: 0.4 !important;
      }

      &~.creditCardMaster,
      &~.creditCard {
        opacity: 0 !important;
      }
    }
  }

  .ccVisa {
    &~.creditCardVisa {
      opacity: 1;
    }

    &.ccInvalid {
      &~.creditCardVisa {
        opacity: 0.4;
      }
    }

    &~.creditCard,
    &~.ccMastercard {
      opacity: 0;
    }
  }

  .ccMastercard {
    &~.creditCardMaster {
      opacity: 1;
    }

    &.ccInvalid {
      &~.creditCardMaster {
        opacity: 0.4;
      }
    }

    &~.creditCard {
      opacity: 0;
    }
  }
}

.form-renew-card {
  max-width: 600px;
}