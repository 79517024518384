* {
    box-sizing: border-box;
}

html {
    min-height: 100%;
}

body {
    background: $gray_lighter;
    font-family: $font-text;
    font-size: $font-size-base;
    font-weight: 300;
    min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-title;
}

a {
    color: $color_brand_primary;
}

article {
    margin-bottom: 30px;
}

strong {
    font-family: $font-text-bold;
}

p {
    &:last-child {
        margin-bottom: 0;
    }
}

.no-gutter {
    margin: 0;
    padding: 0;
}

.rtecenter {
    // text-align: center;
}
