.navbar {
    @include text-nav;
    border: 0;
    border-radius: 0;
    border-top: 5px solid $color_brand_secondary;
    height: auto;
    margin-bottom: 0;
    position: relative;

    h1 {
        @include text-title;
        color: $white;
        font-size: 50px;
        margin-top: 5px;
        text-align: center;

        @include xs {
            font-size: 1.8em;
        }

        img {
            margin: 0 5px;
            margin-top: -10px;
        }
    }

    &.navbar-inverse {
        background: $color_brand_primary;
    }

    .logo {
        fill: $white;
        width: 263px;
    }
}

//titre boutique
.boutique-title {
    font-family: $font-nav;
}

//titre principal
.cm-main-title {
    @include xssm {
        margin: 0 auto;
    }

    h1 {
        font-family: $font-text;
        font-size: 30px;
        margin: 30px 0 10px;

        @include xssm {
            margin: 10px 0;
            padding: 0 10px;
            text-align: center;
        }
    }

    .container {
        padding: 0 30px;
    }

    .row {
        align-items: center;

        @include xs {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}

.cm-title {
    float: left;
}
