.bootstrap-onecol-stacked {
    background: transparent;
}

.node-type-formulaire-perso,
.node-type-webform {
    .main-container {
        background: $gray_lighter;
        max-width: none;

        @include xssm {
            padding: 0;
        }

        .row {
            margin: auto;
            max-width: $max-width-lg;
        }

        &.landing {
            padding: 0;

            .row {
                max-width: none;
            }
        }
    }
}

.main-container {
    background: $gray_lighter;
    padding-top: 15px;

    @include xssm {
        padding-top: 0;
    }
}
