.cm-thank {
    background: $white;
    margin: auto;
    max-width: $max-width-lg;

    .cm-thank-btn {
        text-align: center;
    }

    .btn {
        color: $gray_darker;
        display: inline-block;
        padding: 5px 20px;
        width: auto;
    }
}
