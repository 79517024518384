.onglets-paiement {
    .panel-heading {
        color: $black !important;

        a {
            color: $black !important;
            line-height: 31px;

            &:hover {
                color: $color_brand_primary !important;
            }
        }

        .nav-tabs {
            background: $white;
        }

        .active {
            background-color: $gray_lighter;
        }

        fieldset.panel-default & {
            span {
                font-size: inherit;
            }
        }
    }

    .panel {
        border-radius: 0;
        box-shadow: none;
    }

    iframe {
      max-width: 100%;
    }
}

#edit-submitted-pay-fieldset-paiement {
    .panel-heading {
        display: none;
    }

    > .panel-body {
        border: 0;
        padding: 0;
    }

    .with-nav-tabs {
        border: 0;
        box-shadow: none;

        .panel-heading {
            padding: 0;
        }

        .panel-body {
            border-top: 0;
            padding: 15px;
        }
    }
}

.paiement {
    h1 {
        font-size: 27px;
        margin-top: 0;
    }

    .btn-success {
        color: $black;
        font-size: 21px;
        font-weight: bold;
        padding: 5px 10px;
    }
}

//onglet
.tab-payment-FREE {
    //display: none;
}

.tab-payment-OGU,
.tab-payment-OGR {
    background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/credit_card.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    width: 100px;

    a {
        text-indent: -999em;
    }

    .control-label {
        display: block;
    }
}

.tab-payment-PPU,
.tab-payment-PPR {
    background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/paypal.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    width: 115px;

    a {
        text-indent: -999em;
    }
}

.tab-payment-SEPA {
    background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/sepa.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    width: 115px;

    a {
        text-indent: -999em;
    }
}

.tab-payment-PPU,
#tab-payment-PPU,
.tab-payment-PPR,
#tab-payment-PPR {
    button {
        // background: none;
        // padding: 0;
    }
}

.tab-payment-TWK {
    h2 {
        font-size: 21px;
    }

    a {
        .onglets-paiement .panel-heading & {
            line-height: 23px;
        }
    }

    .tab-logo-TWK {
        background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/twikey.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        width: 70px;
        min-height: 31px;
        display: inline-block;
        vertical-align: text-bottom;
        margin-left: 2px;

        fieldset.panel-default .onglets-paiement .panel-heading & {
            font-size: 0;
        }
    }
}

#tab-payment-OGR_FREE {
    .node-type-webform & {
        h2 {
            display: none;
        }
    }
}

.tab-payment-PAYU,
.tab-payment-PAYR {
    background: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/paypal.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    width: 90px;

    a {
        text-indent: -999em;
    }

    form {
        padding: 15px;
        @include clearfix;
    }
}

.tab-payment-SMS {
    background-image: url('/sites/all/themes/custom/boutique/bootstrapMarketing/img/sms.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 80%;
    width: 60px;

    a {
        text-indent: -999em;
    }

    .texteAbon {
        padding: 0 15px 20px;
    }
}

//paiement form
#tab-payment-PAYU,
#tab-payment-PAYR {
    input[type='image'] {
        display: block;
        float: none;
        margin: 20px;
    }
}

#tab-payment-OGU,
#tab-payment-OGR {
    .form-group {
        display: block;
        margin-bottom: 5px;
    }
}

#edit-submitted-pay-fieldset-paiement-prix {
    font-family: $font-title;
    font-size: 21px;
    font-weight: bold;
    text-align: left;

    label {
        margin: 0;
        width: auto;
    }
}
