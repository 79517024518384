.form-control,
.form-group .form-control {
  background-image: none;
  border: 1px solid $gray_light;
  border-radius: 3px;
  font-size: $font-size-base;
  padding: 0 5px;
  width: 100%;
}

fieldset.panel-default {
  .panel-body {
    padding: 15px 5px 0;
  }
}

.form-group {
  margin: 0;

  label.control-label {
    color: $color_brand_primary;
    font-family: $font-text;
    font-size: $font-size-base;
    line-height: 28px;
    margin-top: 5px;
    padding-left: 0;
    position: static;
  }

  &.label-static,
  &.label-floating.is-focused,
  &.label-floating:not(.is-empty) {
    label.control-label {
      color: $color_brand_primary;
      font-family: $font-text;
      font-size: $font-size-base;
      line-height: 28px;
      position: static;
    }
  }
}

//bouton radio
.radio {
  margin: 0;

  input[type="radio"] {
    margin: 0 10px;
    opacity: 1;
    position: absolute;
    top: 4px;
  }
}

//checkbox
.checkbox {
  margin: 0 0 0 15px;

  input[type="checkbox"] {
    margin: 0 10px;
    opacity: 1;
    position: static;
  }
}

.webform-client-form {
  .webform-component-radios {
    > .control-label {
      color: $color_brand_primary;
      font-family: $font-text;
      font-size: $font-size-h4;
      line-height: 28px;
      margin: 0;
    }

    .form-radios {
      margin-left: 0;
    }

    .radio {
      margin: 0;

      .control-label {
        color: $color_brand_primary;
        font-family: $font-text;
        font-size: $font-size-base;
        line-height: 22px;
        margin: 6px 0 0 15px;
        padding-left: 20px;
        position: relative;
      }
    }
  }
}

//date
.cm-date {
  .webform-calendar,
  .form-item {
    margin-right: 10px;
    padding: 0;
  }

  select.month {
    text-transform: capitalize;

    option {
      text-transform: capitalize;
    }
  }
}
