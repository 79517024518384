// --- Color ---
$color_brand_primary: #228181;
$color_brand_secondary: #50bbb2;

$color_link: #003978;


// --- Fonts ---
$font-nav:  'bebas_neue_regularregular', sans-serif;
$font-text:  'ralewayregular', arial, sans-serif;
$font-text-semibold:  'ralewaysemibold', arial, sans-serif;
$font-text-bold:  'ralewaybold', arial, sans-serif;
$font-title:  oswald, arial, sans-serif;

$font-size-base: 15px;
$font-size-small: 12px;
$font-size-h1: 30px;
$font-size-h2: 24px;
$font-size-h3: 21px;
$font-size-h4: 17px;
