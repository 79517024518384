@charset 'UTF-8';
// Default Variables
$slick-loader-path: './' !default;
$slick-arrow-color: $white !default;
$slick-dot-color: $black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '←' !default;
$slick-next-character: '→' !default;
$slick-dot-character: '•' !default;
$slick-dot-size: 30px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    } @else {
        @return url($slick-font-path + $url);
    }
}

.view-argumentaires {
    background: #1a1a1a;

    .view-content {
        .col-sm-9 {
            padding: 0;
        }
    }

    .btn {
        margin: 5px 5px 5px 0;
        padding: 10px 20px;

        @include xs {
            margin: 5px 5px 15px 0;
        }
    }
}


// Arrows

.slick-prev,
.slick-next {
    background: transparent;
    border: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 96px;
    line-height: 0;
    margin-top: -10px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    width: 40px;

    &:hover,
    &:focus {
        background: transparent;
        color: transparent;
        outline: none;
    }

    &:before {
        color: $gray-light;
        display: block;
        font-family: icons;
        font-size: 80px;
        height: 80px;
        line-height: 80px;
    }
}

.slick-prev {
    left: 1%;
    z-index: 999;

    &:before {
        content: '\E007';
    }
}

.slick-next {
    right: 1%;

    &:before {
        content: '\E006';
    }
}

// Dots

.slick-slider {
    overflow: hidden;
    width: 100%;
}

.slick-dots {
    bottom: -35px;
    display: block;
    list-style: none;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;

    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;

        button {
            background: transparent;
            border: 0;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0;
            height: 20px;
            line-height: 0;
            outline: none;
            padding: 5px;
            width: 20px;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }

            &:before {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                color: $slick-dot-color;
                content: $slick-dot-character;
                font-size: $slick-dot-size;
                height: 20px;
                left: 0;
                line-height: 20px;
                opacity: $slick-opacity-not-active;
                position: absolute;
                text-align: center;
                top: 0;
                width: 20px;
            }
        }

        &.slick-active {
            button {
                &:before {
                    color: $slick-dot-color-active;
                    opacity: $slick-opacity-default;
                }
            }
        }
    }
}
