div.krumo-root {
    color: $black !important;

    a {
        color: $black !important;
    }
}

.cm-messages {
    clear: both;
    margin: auto;
    max-height: 50vh;
    max-width: $max-width-lg;
    overflow: auto;

    .messages {
        margin: 20px auto 0;
    }
}
