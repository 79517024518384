.btn {
    border-radius: 8px;
    font-family: $font-text-bold;
    font-size: $font-size-h4;
    text-transform: none;
}

.btn_more_abo {
    button {
        background: $color_brand_secondary;
        border-radius: 10px;
        color: #fff;
        font-family: $font-text-semibold;
        font-size: 1.3em;
        line-height: 1em;

        @include xs {
            font-size: 1em;
        }

        &:hover {
            background-color: lighten($color_brand_secondary, 20%);
            text-decoration: none;
        }
    }
}

// Bouton payer - #92104
.btn:not(.btn-raised).btn-success, .input-group-btn .btn:not(.btn-raised).btn-success {
  color: $black;
}
