// ######## material design ########
@import '../bootstrap-material-design/mixins';
@import '../bootstrap-material-design/shadows';
@import '../bootstrap-material-design/core';

// ######## project ########
@import 'projet/fonts';
@import 'projet/base';
@import 'projet/icons';


@import 'layout/admin';
@import 'layout/header';
@import 'layout/structure';
@import 'layout/page';
@import 'layout/kiosque';
@import 'layout/thankyou';
@import 'layout/wallet';
@import 'layout/footer';

//module
@import 'module/alerts';
@import 'module/menu_main';
@import 'module/menu_formule';
@import 'module/horizon-swiper';
@import 'module/horizon-theme';
@import 'module/box_offre';
@import 'module/btn';
@import 'module/slick';
@import 'module/slick-theme';
@import 'module/carousel';
@import 'module/forms';
@import 'module/fields';
@import 'module/call_center';
@import 'module/payment';
@import 'module/faq';
@import 'module/landing-module';
@import 'module/spinner';
@import 'module/overlay';
@import 'module/application';
