//global
.node-type-kiosque {
    .cm-main-title {
        h1 {
            font-family: $font-text;
            font-size: 40px;
            margin-bottom: 0;
            @include sm {
                margin-top: 0;
            }
        }
    }

    .cm-form-left {
        margin-left: 45px;
        margin-right: 30px;
        margin-top: 0;
        padding: 150px 15px 15px;
        position: relative;
        width: calc(66% - 60px);

        @include md {
            margin: 0 15px;
            width: calc(66% - 30px);
        }

        @include xssm {
            margin: auto;
            width: auto;
            @include clearfix;
        }

        @include xs {
            margin: 0 10px 10px;
            padding: 0;
        }
    }

    .cm-form-right {
        margin-left: 0;
        margin-top: 0;
        padding: 0;
        width: calc(34% - 60px);

        @include md {
            margin: 0 15px 0 0;
            width: calc(34% - 15px);
        }

        @include xssm {
            margin: 10px auto;
            width: auto;
        }

        @include xs {
            margin: 0 10px;
        }
    }

    .cm-form-content {
        padding: 15px;
    }

    .onglets-paiement {
        .panel-heading {
            display: none;
        }

        .panel-body {
            padding-top: 0;

            .tab-pane {
                border-bottom: 5px solid $gray_light;
                display: block !important;
                margin-bottom: 30px;
                padding-bottom: 30px;

                &:last-child {
                    border: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .field-name-field-visuel-grand {
        width: 100%;

        .img-responsive {
            box-shadow: 0 0 1px $gray_light;
            transform: none;
            width: 80%;
            margin-left: 15%;
            margin-top: 45px;
            
            @include xs {
                margin-top: 15px;
            }
        }
    }

    .field-name-field-prix-affiche {
        background: $color_brand_secondary;
        color: $color_brand_primary;
        font-family: $font-title;
        font-size: 55px;
        left: 50%;
        margin: 0;
        position: absolute;
        top: 120px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border: 3px solid #fff;

        @include xs {
            font-size: 45px;
            right: -10px;
            left: auto;
            top: -20px;
        }
    }

    .cm-kiosque-title {
        font-size: 35px;
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        
        @include xs {
            position: static;
            font-size: 25px;
        }

        strong {
            color: $color_brand_secondary;
        }
    }

    .cm-kiosque-wrap {
        float: right;
        width: 50%;

        @include xs {
            float: none;
            width: auto;
        }
    }

    .field-name-field-descriptif-long {
        border-right: 1px solid $color_brand_secondary;
        float: left;
        padding-right: 10px;
        width: 50%;

        @include xs {
            border: 0;
            float: none;
            width: auto;
        }
        
        img {
            width: 72%;
            display: block;
            margin: auto;
        }
    }

    .main-container {
        max-width: 1980px;
    }
}
