// --- Color ---
$color_brand_primary: #333;
$color_brand_secondary: #fdc800;

$color_link: #0090c6;

$white: #fff;
$black: #000;

$gray_lighter: #f5f5f5;
$gray_light: #d6d6d6;
$gray: #797979;
$gray_dark: #575757;
$gray_darker: #242424;


// --- Social color ---
$facebook: #3c5a98;
$twitter: #28aae1;
$google: #dd4b38;
$instagram: #4d3e33;
$linkedin: #069;
$dailymotion: #052e7f;

// --- Fonts ---
$font-text: 'open_sanslight', arial, sans-serif;
$font-text-semibold: 'open_sanssemibold', arial, sans-serif;
$font-text-bold: 'open_sansbold', arial, sans-serif;
$font-nav: 'oswald', arial, sans-serif;
$font-title: 'oswald', arial, sans-serif;

$font-size-base: 17px;
$font-size-small: 13px;
$font-size-h1: 40px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 21px;


// --- Size ---
$screen-xxs: 320px;
$max-width-xs: 500px;
$max-width-sm: 750px;
$max-width-md: 970px;
$max-width-lg: 1170px;

// --- media queries ---
$screen-xlg-min: 1920px;

// <= 480
$xxs: '(max-width: ' + $screen-xs + ')';
$xxs-d: '(min-device-width :	' + $screen-xxs + ' ) and (max-device-width: ' + $screen-xs - 1 + ')';
$xxs-d-portrait: '(max-device-width: ' + $screen-xs + ') and (orientation: portrait)';

$xs: '(max-width: ' + $screen-xs-max + ')'; // <= 767
$xs-d: '(max-device-width: ' + $screen-xs-max + ')'; // <= 767
$xs-d-portrait: '(max-device-width: ' + $screen-xs-max + ') and (orientation: portrait)'; // <= 767
$xs-d-landscape: '(max-device-width: ' + $screen-xs-max + ') and (orientation: landscape)'; // <= 767

$sm-d: '(min-device-width: ' + $screen-sm-min + ') and (max-device-width: ' + $screen-sm-max + ')'; // 768 -> 991
$sm: '(min-width: ' + $screen-sm-min + ') and (max-width: ' + $screen-sm-max + ')'; // 768 -> 991

$md: '(min-width: ' + $screen-md-min + ') and (max-width: ' + $screen-md-max + ')'; // 992 -> 1199
$md-d: '(min-device-width: ' + $screen-md-min + ') and (max-device-width: ' + $screen-md-max ')'; // 992 -> 1199

$lg: '(min-width: ' + $screen-lg-min + ')'; // 1200 <

$xssm: '(max-width: ' + $screen-sm-max + ')'; // 768 -> 991;
$xssm-d: '(max-device-width: ' + $screen-sm-max + ')'; // 768 -> 991;

$smmdlg: '(min-width: ' + $screen-sm-min + ')'; // 768 >= ;
$smmdlg-d: '(min-device-width: ' + $screen-sm-min + ')'; // 768 >= ;

$mdlg: '(min-width: ' + $screen-md-min + ')'; // 992 >= ;
$mdlg-d: '(min-device-width: ' + $screen-md-min + ')';

$xlg: '(min-width: ' + $screen-xlg-min + ')';
