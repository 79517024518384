// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

// Support for Selectize plugin
// http://brianreavis.github.io/selectize.js/

.selectize-control.single, .selectize-control.multi {
    padding: 0;
    .selectize-input, .selectize-input.input-active {

        cursor: text;
        background: transparent;
        box-shadow: none;
        border: 0;
        padding: 0;
        height: 100%;
        font-size: 14px;
        line-height: 30px;
        .has-items {
            padding: 0;
        }
        &:after {
            right: 5px;
            position: absolute;
            font-size: 25px;
            content: "\e5c5";
            font-family: 'Material Icons';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        input {
            font-size: 14px;
            outline: 0;
            border: 0;
            background: transparent;
        }
        &.label-floating-fix input {
            opacity: 0;
        }
        > div, > .item {
            display: inline-block;
            margin: 0 8px 3px 0;
            padding: 0;
            background: transparent;
            border: 0;
            &:after {
                content: ",";
            }
            &:last-of-type:after {
                content: "";
            }
            &.active {
                font-weight: bold;
                background: transparent;
                border: 0;
            }
        }
    }
    .selectize-dropdown {
        position: absolute;
        z-index: 1000;
        border: 0;
        width: 100% !important;
        left: 0 !important;
        height: auto;
        background-color: #FFF;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: $border-radius-base;
        padding: 0;
        margin-top: 3px;
        .active {
            background-color: inherit;
        }
        .highlight {
            background-color: #d5d8ff;
        }
        .selected, .selected.active {
            background-color: #EEEEEE;
        }
        [data-selectable], .optgroup-header {
            padding: 10px 20px;
            cursor: pointer;
        }
    }
    .dropdown-active ~ .selectize-dropdown {
        display: block;
    }
}
