//colonnage des blocs offres selon vues
.carousel-promos,
.offre-principale {
    @include sm {
        @include make-sm-column(6);
        padding: 0 10px;
    }
    @include mdlg {
        @include make-md-column(3);
    }
}

//vue 2 aligner les blocs
.middle-top {
    .panel-separator {
        &:nth-child(4) {
            @include sm {
                clear: both;
                margin-top: 40px;
            }
        }
    }
}

.cm-top-offres {
    height: 100%;
    .view-content {
        height: 100%;
        @include flex;
        @include flex-justify(center);

        @include xssm {
            @include flex-wrap(wrap);
        }
    }
}

.surtitre {
    background-color: $color_brand_primary;
    color: $white;
    font-family: $font-nav;
    font-size: 1em;
    font-weight: bold;
    line-height: 1em;
    margin: 0 0 10px;
    padding: 8px;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
}

//blocs offres
.wrap-offre {
    @include flex;
    float: left;
    margin: 0;
    padding: 0 10px;
    width: 33.33%;

    @include xssm {
        margin: 10px auto;
        max-width: 300px;
        width: 100%;
    }

    &:hover {
        .btn_continuer {
            visibility: visible;
        }
    }

    .wrap-offre {
        margin-bottom: 30px;

        @include xs {
            margin: 10px;
        }
    }

    .box-offre {
        @include flex;
        background-color: $white;
        box-shadow: 0 0 1px #ccc;
        min-height: 492px;
        padding: 15px;
        position: relative;
        transition: all .5s ease;
        width: 100%;

        &:hover {
            @include mdlg {
                background-color: $color_brand_secondary;
                position: relative;

                h2 {
                    border-bottom: 1px solid lighten($color_brand_secondary, 10%);
                    color: $color_brand_primary;
                }

                .price {
                    background: $color_brand_primary;
                    color: $white;
                }
            }
        }

        a {
            @include flex;
            @include flex-direction(column);
            color: $black;
            outline: none;
            width: 100%;
            width: calc(100% - 0px); //hack IE10

            &:before {
                bottom: 0;
                content: '';
                display: block;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
            }

            &:hover {
                text-decoration: none;
            }

            .btn {
                display: none;
            }
        }

        h2 {
            border-bottom: 1px solid $gray_lighter;
            font-size: 2em;
            margin: 0 0 10px;
            padding: 8px;
            text-align: center;
            transition: all .5s ease;
        }

        p {
            margin-bottom: 0;
            margin-top: 10px;
            padding: 5px 10px 0;
            white-space: normal;
        }

        img {
            display: block;
            margin: auto;
        }

        .img-responsive {
            margin: 0;
        }

        &.premium {
            background-color: $color_brand_primary;
            color: $white;

            &:hover {
                @include mdlg {
                    background-color: $color_brand_secondary;
                    position: relative;

                    a {
                        color: $black;
                    }

                    h2 {
                        border-bottom: 1px solid lighten($color_brand_secondary, 10%);
                        color: $color_brand_primary;
                    }

                    .price {
                        background: $color_brand_primary;
                        color: $white;
                    }

                    .img-responsive {
                        box-shadow: 0 0 1px #ccc;
                    }
                }
            }

            a {
                color: $white;
            }

            .price {
                background: $color_brand_secondary;
                color: $color_brand_primary;
            }
        }
    }

    .btn_continuer {
        visibility: hidden;
    }
}

.offres-supplementaires {
    .view-content {
        //@include flex;
        @include flex-wrap(wrap);
        display: none;

        &.is-visible {
            @include flex;
        }
    }

    .wrap-offre {
        float: left;
        margin: 0;
        padding: 10px;
        width: 25%;

        @include xssm {
            max-width: none;
            padding: 15px 10px;
            width: 100%;
        }
    }
}

// Affichage du prix
.price {
    @include text-title;
    font-size: 1.7rem;
    line-height: 2rem;
    margin: auto 0 -15px;
    padding: 20px 0;
    text-align: center;
    transition: all .5s ease;

    strong,
    span {
        display: inline-block;
        font-family: $font-title;
        font-size: 6rem;
        line-height: 6rem;
        text-transform: uppercase;
        vertical-align: middle;

        .cm-form-left & {
            color: $color_brand_primary;
            font-size: 17rem;
            line-height: 17rem;

            @include xs {
                font-size: 10rem;
                line-height: 10rem;
            }
        }
    }

    .price_wrap {
        display: inline-block;
    }

    span {
        display: block;
        font-size: 1.2rem;
        line-height: 1.2rem;

        + .price_wrap {
            display: block;
        }

        .cm-form-left & {
            font-size: 3rem;
            line-height: normal;

            @include xs {
                font-size: 2rem;
            }
        }
    }

    strong {
        padding: 7px 0;

        span {
            display: inline;
            vertical-align: top;
        }
    }
}

.price_diminue {
    color: #f00;
    display: inline-block;
    margin: 10px 2px;
}

.price_barre {
    display: inline-block;
    font-size: 2rem;
    line-height: 50px;
    margin: 0 2px;
    position: relative;

    &:before {
        -moz-transform: rotate(-20deg);
        -o-transform: rotate(-20deg);
        -webkit-transform: rotate(-20deg);
        background: #f00;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        top: 50%;
        transform: rotate(-20deg);
        width: 100%;
    }
}

.pane-title {
    display: none;
}

.description {
    margin: 10px;
    text-transform: uppercase;

    strong {
        @include text-bold;
        font-size: 1em;
        text-transform: capitalize;
    }
}
