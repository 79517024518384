.cm-form-left,
.cm-form-right {
    margin: 15px 0 30px;
    width: 48%;

    @include xssm {
        margin: 15px auto 30px;
        max-width: 700px;
        width: auto;
    }

    @include xs {
        margin: 15px;
    }
}

.cm-form-left {
    margin-right: 4%;
    margin-top: 15px;
    padding: 10px;

    @include sm {
        margin: 15px auto 30px;
    }

    @include xs {
        margin: 15px;
    }

    .price {
        display: none;
    }
}

.cm-form-content {
    max-width: $max-width-xs;
    padding: 30px;

    @include xs {
        min-width: 0;
        padding: 10px;
        width: auto;
    }
}

.webform-submit,
.webform-next {
    .form-actions &.btn {
        font-family: $font-text-semibold;
        font-size: 1.2em;
    }
}

.webform-previous {
    &.btn {
        font-family: $font-text;
        margin-right: 15px;
        padding: 7px;

        &:hover {
            background: none !important;
            text-decoration: underline;
        }
    }
}

//login
.cm-logout {
    display: none;
}

// barre d'étapes
.cm-progressbar {
    &:first-child {
        .cm-progressbar-page {
            padding: 10px 30px 5px;

            &.current {
                background: $white;
                border-bottom: 1px solid #e7e7e7;
                border-top: 0;
            }
        }
    }
}

.cm-progressbar-page {
    border-top: 1px solid #e7e7e7;
    font-size: 3.9rem;
    line-height: 4.2rem;
    padding: 15px 30px;

    @include xs {
        font-size: 2.5rem;
        padding: 5px 10%;
    }

    &.current {
        border-top: 1px solid #e7e7e7;
        color: $gray_dark;
    }
}
