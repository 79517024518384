@mixin text-regular($font-size: 1.4em, $line-height: 1.3em, $font-family: $font-text, $font-style: 'normal') {
    font-family: $font-family;
    font-size: $font-size;
    font-style: $font-style;
    line-height: $line-height;
}

@mixin text-semibold($font-size: 1.4em, $line-height: 1.3em, $font-family: $font-text-semibold, $font-style: 'normal') {
    @include text-regular($font-size, $line-height, $font-family, $font-style);
}

@mixin text-bold($font-size: 1.4em, $line-height: 1.3em, $font-family: $font-text-bold, $font-style: 'normal') {
    @include text-regular($font-size, $line-height, $font-family, $font-style);
}

@mixin text-nav($font-size: 1.3em, $line-height: 1.3em, $font-family: $font-nav, $font-style: 'normal') {
    @include text-regular($font-size, $line-height, $font-family, $font-style);
}

@mixin text-title($font-size: 2.4em, $line-height: 1.3em, $font-family: $font-title, $font-style: 'normal') {
    @include text-regular($font-size, $line-height, $font-family, $font-style);
}

@mixin flex {
    -js-display: flex;
    display: -webkit-box;
    display: -moz-box;
    //display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
}

@mixin flex-width($num) {
    -moz-box-flex: $num;
    -ms-flex: $num;
    -webkit-box-flex: $num;
    -webkit-flex: $num;
    flex: $num;
}

@mixin flex-order($num) {
    -moz-box-ordinal-group: $num;
    -ms-flex-order: $num;
    -webkit-box-ordinal-group: $num;
    -webkit-order: $num;
    order: $num;
}

@mixin flex-wrap($wrap: nowrap) {
    -moz-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
    -webkit-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

@mixin flex-justify($justify: flex-start) {
    -ms-justify-content: $justify;
    -webkit-justify-content: $justify;
    justify-content: $justify;
}

@mixin flex-direction($direction) {
    -moz-flex-direction: $direction;
    -ms-flex-direction: $direction;
    -webkit-flex-direction: $direction;
    flex-direction: $direction;
    @if $direction == column {
        -webkit-box-orient: vertical;
    } @else if $direction == row {
        -webkit-box-orient: horizontal;
    }
}

@mixin appearance($value) {
    -moz-appearance: $value;
    -webkit-appearance: $value;
    appearance: $value;
}

@mixin xxs($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xxs-d !global;

    } @else if $mode == dl {
        $query: $xxs-d-landscape !global;

    } @else if $mode == dp {
        $query: $xxs-d-portrait !global;

    } @else {
        $query: $xxs !global;
    }

    @media #{$query} #{$argument} {
        @content;
    }
}

@mixin xs($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xs-d !global;

    } @else if $mode == dl {
        $query: $xs-d-landscape !global;

    } @else if $mode == dp {
        $query: $xs-d-portrait !global;

    } @else {
        $query: $xs !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin sm($mode: null, $argument: null) {
    @if $mode == d {
        $query: $sm-d !global;

    } @else if $mode == dl {
        $query: $sm-d-landscape !global;

    } @else if $mode == dp {
        $query: $sm-d-portrait !global;

    } @else {
        $query: $sm !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin md($mode: null, $argument: null) {
    @if $mode == d {
        $query: $md-d !global;

    } @else if $mode == dl {
        $query: $md-d-landscape !global;

    } @else if $mode == dp {
        $query: $md-d-portrait !global;

    } @else {
        $query: $md !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin lg($mode: null, $argument: null) {
    @if $mode == d {
        $query: $lg-d !global;

    } @else if $mode == dl {
        $query: $lg-d-landscape !global;

    } @else if $mode == dp {
        $query: $lg-d-portrait !global;

    } @else {
        $query: $lg !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin xlg($argument: null) {
    @media ($xlg) #{argument} {
        @content;
    }
}

@mixin xssm($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xssm-d !global;

    } @else if $mode == dl {
        $query: $xssm-d-landscape !global;

    } @else if $mode == dp {
        $query: $xssm-d-portrait !global;

    } @else {
        $query: $xssm !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin smmd($mode: null, $argument: null) {
    @if $mode == d {
        $query: $smmd-d !global;

    } @else if $mode == dl {
        $query: $smmd-d-landscape !global;

    } @else if $mode == dp {
        $query: $smmd-d-portrait !global;

    } @else {
        $query: $smmd !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin mdlg($mode: null, $argument: null) {
    @if $mode == d {
        $query: $mdlg-d !global;

    } @else if $mode == dl {
        $query: $mdlg-d-landscape !global;

    } @else if $mode == dp {
        $query: $mdlg-d-portrait !global;

    } @else {
        $query: $mdlg !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin xssmmd($mode: null, $argument: null) {
    @if $mode == d {
        $query: $xssmmd-d !global;

    } @else if $mode == dl {
        $query: $xssmmd-d-landscape !global;

    } @else if $mode == dp {
        $query: $xssmmd-d-portrait !global;

    } @else {
        $query: $xssmmd !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}

@mixin smmdlg($mode: null, $argument: null) {
    @if $mode == d {
        $query: $smmdlg-d !global;

    } @else if $mode == dl {
        $query: $smmdlg-d-landscape !global;

    } @else if $mode == dp {
        $query: $smmdlg-d-portrait !global;

    } @else {
        $query: $smmdlg !global;
    }

    @media #{$query} #{argument} {
        @content;
    }
}
