//reset field collection
.field-collection-view {
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
}

.field-collection-container {
    margin: 0;
    position: relative;

    .field-items {
        .field-item {
            margin: auto;
        }
    }

    .field-collection-description {
        margin: 0;
    }

    .action-links-field-collection-add {
        bottom: 10px;
        position: absolute;
        right: 110px;
    }
}

.field-collection-view-links {
    background: $white;
    bottom: 10px;
    position: absolute;
    right: 10px;
}

//
.landing {
    &.cm-messages {
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
    }

    &.cm-main-title {
        display: none;
        //margin: 0;
        //@include flex;

        .container {
            //margin: auto;
        }
    }

    .cm-form-right,
    .cm-form-left {
        margin-left: 2%;
        margin-right: 0;
        width: 47%;

        @include xs {
            margin: 10px;
            width: auto;
        }

        @include sm {
            margin: 10px auto;
            width: auto;
        }
    }

    .field-name-field-visuel-grand {
        display: none;
    }

    .node-type-formulaire-perso &,
    .node-type-webform & {
        &.cm-main-title {
            background: rgba($black, .5);
            color: $white;
            height: 50vh;
            position: relative;
            text-shadow: 0 0 1px $black;

            h1 {
                font-size: 5vw;

                @include xssm {
                    font-size: 7vw;
                }
            }

            .row {
                height: 50vh;
                @include flex;
            }

            .btn {
                color: $white;
                font-size: 2.5rem;
                margin: auto;
                max-width: 300px;
                padding: 4px 15px;
                text-shadow: none;
                width: auto;
            }
        }

        .cm-title {
            margin: auto;
        }

        .btn {
            display: block;
        }
    }
}

//caché si on est pas en page 1
.field-name-field-landing-module {
    .webform-page-2 &,
    .webform-page-3 &,
    .webform-page-4 &,
    .webform-page-5 &,
    .webform-page-6 &,
    .webform-page-7 &,
    .webform-page-8 &,
    .webform-page-9 & {
        display: none;
    }
}

//module color/font
.cm-mod-12,
.cm-mod-6-6-txt-img,
.cm-mod-6-6-img-txt,
.cm-mod-img-bg,
.cm-mod-img-bg-repeat,
.cm-mod-carousel,
.cm-mod-img-parallax {
    font-size: 1.5rem;

    h3,
    h4,
    h5,
    h6 {
        font-family: $font-text;
        margin-bottom: 20px;

        &:after {
            background: $color_brand_secondary;
            content: '';
            display: block;
            height: 1px;
            margin-top: 10px;
            width: 25%;
        }
    }

    h3 {
        font-size: 5rem;

        @include xssm {
            font-size: 3rem;
        }
    }

    h4 {
        font-size: 3.5rem;

        @include xssm {
            font-size: 2.5rem;
        }
    }

    h5 {
        font-size: 2.5rem;

        @include xssm {
            font-size: 1.8rem;
        }
    }

    h6 {
        font-size: 2rem;

        @include xssm {
            font-size: 1.3rem;
        }
    }

    a {
        color: $color_brand_secondary;
    }

    strong {
        font-family: $font-text-bold;
    }

    p {
        line-height: 2.3rem;
        margin-bottom: 15px;
    }

    ul {
        list-style: square;
    }

    li {
        margin-bottom: 10px;
    }

    blockquote {
        border-left: 5px solid $color_brand_secondary;
    }

    &.white {
        background-color: $white;
    }

    &.dark {
        background-color: $gray_darker;
        color: $white;
    }

    &.light {
        background-color: $gray_lighter;
    }

    &.soir {
        background-color: $color_brand_primary;
        color: $white;
    }
}

.cm-mod-img-bg,
.cm-mod-img-bg-repeat,
.cm-mod-img-parallax {
    &.white {
        background-color: rgba($white, .5);

        .field-name-field-mod-texte {
            color: $black;
            text-shadow: 0 0 1px $gray;
        }
    }

    &.dark {
        background-color: rgba($gray_darker, .3);
        color: $white;
    }

    &.light {
        background-color: rgba($gray_lighter, .5);

        .field-name-field-mod-texte {
            color: $black;
            text-shadow: 0 0 1px $gray;
        }
    }

    &.soir {
        background-color: rgba($color_brand_primary, .3);
        color: $white;
    }
}

//module flex
.cm-mod-12,
.cm-mod-6-6-txt-img,
.cm-mod-6-6-img-txt,
.cm-mod-img-bg,
.cm-mod-img-bg-repeat,
.cm-mod-img-parallax {
    @include clearfix;
    @include flex;
    @include xssm {
        @include flex-direction(column);
    }

    .field-name-field-mod-image {
        margin: auto;

        .field-items {
            @include flex;
        }
    }

    .field-name-field-mod-texte {
        margin: auto;

        .field-items {
            @include flex;
            margin: 30px auto;
        }

        .field-item {
            margin: 0 auto;
            max-width: 700px;
        }
    }
}

//module 1 colonne image
.cm-mod-12 {
    @include flex-direction(column);

    .field-name-field-mod-image {
        img {
            height: auto;
            width: 100%;
        }
    }

    .field-item {
        margin: auto;
    }
}

//module 2 colonnes image et texte
.cm-mod-6-6-img-txt {
    .field-name-field-mod-image {
        width: 50%;

        @include xssm {
            width: 100%;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .field-name-field-mod-texte {
        width: 50%;

        @include xssm {
            width: 100%;
        }

        .field-items {
            margin: 30px auto;
            max-width: $max-width-xs;

            @include xssm {
                max-width: 700px;
            }
        }
    }
}

//module 2 colonnes texte et image
.cm-mod-6-6-txt-img {
    .field-name-field-mod-image {
        width: 50%;
        @include flex-order(2);

        @include xssm {
            width: 100%;
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    .field-name-field-mod-texte {
        width: 50%;

        @include xssm {
            width: 100%;
        }

        .field-items {
            margin: 30px auto;
            max-width: $max-width-xs;

            @include xssm {
                max-width: 700px;
            }
        }
    }
}

//module image background
.cm-mod-img-bg {
    background-position: center top;
    background-repeat: no-repeat;
    min-height: 400px;

    .field-name-field-mod-image {
        display: none;
    }

    .field-name-field-mod-texte {
        color: $white;
        text-shadow: 0 0 1px #333;
    }
}

//module image papier peint
.cm-mod-img-bg-repeat {
    background-repeat: repeat;
    min-height: 400px;

    .field-name-field-mod-image {
        display: none;
    }

    .field-name-field-mod-texte {
        color: $white;
        text-shadow: 0 0 1px #333;
    }
}

//module image parallax
.cm-mod-img-parallax {
    min-height: 400px;

    .field-name-field-mod-image {
        display: none;
    }

    .field-name-field-mod-texte {
        color: $white;
        text-shadow: 0 0 1px #333;
    }
}

//module carousel
.cm-mod-carousel {
    .field-name-field-mod-image {
        margin: 30px auto;
    }

    .field-name-field-mod-texte {
        margin: 30px auto;
        max-width: 800px;

        .field-item {
            margin: 0 15px;
        }

        .slick-slider {
            padding: 0 40px;
        }
    }
}
