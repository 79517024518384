// General
#cm-main-menu ul,
#cm-menu-formule ul,
.horizon-swiper {
    &.arrows {
        padding: 0 50px;
    }

    // Dots
    .horizon-dots {
        padding: 0 0 6px;

        .horizon-dot {
            background: none;
            border: 1px solid #2c3e50;
            border-radius: 50%;
            font-size: 0;
            height: 12px;
            line-height: 1;
            padding: 0;
            transition: background .3s;
            width: 12px;
        }
    }

    // Arrows
    .horizon-prev,
    .horizon-next {
        background: none;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        bottom: 5px;
        outline: none;
        position: absolute;
        top: 5px;
        width: 40px;

        &:hover,
        &:active,
        &:focus {
            outline: none;
        }
    }

    .horizon-prev {
        background-image: url('../../bootstrapMarketing/img/arrow-left.svg');
        left: 5px;
    }

    .horizon-next {
        background-image: url('../../bootstrapMarketing/img/arrow-right.svg');
        right: 5px;
    }
}
