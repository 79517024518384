// @font-face {
//     font-family: 'economica';
//     font-style: normal;
//     font-weight: normal;
//     src: url('../fonts/economica/Economica-Regular.ttf') format('truetype');
// }

@font-face {
    font-family: 'oswald';
    font-style: normal;
    font-weight: bold;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/Oswald/oswald-bold-webfont.svg#oswaldbold') format('svg');
}

@font-face {
    font-family: 'bebas_neue_regularregular';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/bebas-neue/bebasneue_regular-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/bebas-neue/bebasneue_regular-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/bebas-neue/bebasneue_regular-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/bebas-neue/bebasneue_regular-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/bebas-neue/bebasneue_regular-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/bebas-neue/bebasneue_regular-webfont.svg#bebas_neue_regularregular') format('svg');
}

@font-face {
    font-family: 'ralewayregular';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-regular-webfont.svg#ralewayregular') format('svg');
}

@font-face {
    font-family: 'ralewaysemibold';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-semibold-webfont.svg#ralewaysemibold') format('svg');
}

@font-face {
    font-family: 'ralewaybold';
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-bold-webfont.eot');
    src: url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-bold-webfont.woff2') format('woff2'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-bold-webfont.woff') format('woff'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-bold-webfont.ttf') format('truetype'), url('/sites/all/themes/custom/boutique/bootstrapCarbonMarketing/fonts/raleway/raleway-bold-webfont.svg#ralewaybold') format('svg');
}
