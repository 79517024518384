.row {
    &.top {
        margin: 0;
    }
}

.middle {
    margin: 0 auto;
    max-width: $max-width-lg;
}

.middle-top {
    margin: 15px auto 0;
    @include flex;
    text-align: center;

    @include xssm {
        @include flex-direction(column);
    }

    .pane-content {
        height: 100%;
    }

    .panel-pane {
        margin: 0 auto;
        text-align: left;
        @include make-xs-column(12);

        @include xssm {
            margin-bottom: 30px;
            padding: 0;
        }

        @include xs {
            margin-bottom: 10px;
        }

        &.pane-encarts-promotionnels-panel-pane-1 {
            @include make-md-column(3);

            @include xs {
                order: 3;
            }

            &.-is-top {
                @include xs {
                    order: 1;
                }
            }

            @include mdlg {
                padding: 0 10px;
            }
        }

        &.pane-taxonomy-term-reordered-panel-pane-2 {
            @include make-md-column(9);

            @include xs {
                order: 2;
            }

            @include mdlg {
                padding: 0;
            }
        }
    }
}

.middle-bottom {
    margin-bottom: 30px;
    padding: 0;

    @include xssm {
        padding: 0;
    }
}

.bottom {
    margin: 0;
}

.bootstrap-onecol-stacked {
    background: $gray_lighter;
}

.main-container {
    max-width: 1980px;
    width: 100%;

    @include xssm {
        max-width: 100%;
    }

    .node-type-formulaire-perso &,
    .node-type-webform & {
        max-width: $max-width-lg;
    }
}
