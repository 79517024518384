#cm-menu-formule {
    display: none;
    background: #e7e7e7;
    clear: none;
    height: 60px;

    @include xssm {
        height: auto;
    }

    ul {
        float: right;
        text-align: right;

        @include xssm {
            float: none;
            text-align: center;
        }
    }

    li {
        a {
            color: $color_brand_primary;
            display: block;
            font-size: 24px;
            height: auto;
            line-height: 30px;
            margin: 24px 1px 0;
            padding: 0 15px;
            text-transform: uppercase;

            @include md {
                font-size: 20px;
            }

            @include xssm {
                font-size: 20px;
                margin: 1px 0;
            }

            &:hover {
                color: lighten($color_brand_primary, 15%);
            }
        }

        .active {
            font-weight: bold;
        }
    }
}