// Slider 
.slick-slider {
    -khtml-user-select: none;
    -moz-box-sizing: border-box;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;

    .slick-track,
    .slick-list {
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    // sass-lint:disable no-duplicate-properties
    &.dragging {
        cursor: pointer; //fallback
        cursor: hand;
    }
    // sass-lint:enable no-duplicate-properties
}

.slick-track {
    display: block;
    display: inherit;
    left: 0;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    position: relative;

    [dir='rtl'] & {
        float: right;
    }

    img {
        display: block;
        min-width: 100%;

        @include xs {
            width: 100%;
        }
    }

    &.slick-loading {
        img {
            display: none;
        }
    }

    &.dragging {
        img {
            pointer-events: none;
        }
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.overlay {
    background: rgba($black, .67);
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 999;
}

.slick-arrow {
    &.slick-hidden {
        display: none;
    }
}

.slick-active {
    .overlay {
        display: none;
    }
}

.slider_txt {
    color: $white;

    h2 {
        @include text-title($font-size: 1.5em);
        text-transform: uppercase;
    }

    h3 {
        font-size: 1.2em;
    }
}


.btn_lire {
    @include xs {
        padding: 10px;
        width: auto;
    }
}
