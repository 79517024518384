#cm-main-menu {
    display: none;
    background-color: transparent;
    float: left;
    font-size: 2.4em;
    height: 60px;

    @include lg {
        margin-left: calc(50% - 575px);
    }

    @include md {
        margin-left: 0;
    }

    @include xssm {
        background: #e7e7e7;
        float: none;
        font-size: 2em;
        height: 45px;
        margin-left: 0;
    }

    .container {
        width: auto;
    }

    a {
        color: $color_brand_primary;
        font-family: $font-nav;

        &:hover {
            color: lighten($color_brand_primary, 15%);
            text-decoration: none;
        }
    }

    //si on est pas dans le kiosque, le premier lien est actif
    body:not(.node-type-kiosque) & {
        li {
            @include mdlg {
                height: 60px;
                padding-top: 10px;
            }

            &:first-child {
                background: $gray_lighter;

                @include xssm {
                    background: transparent;
                    height: 50px;
                }

                a {
                    color: $color_brand_secondary;

                    &:hover {
                        color: $color_brand_primary;
                    }
                }
            }
        }
    }

    .node-type-kiosque & {
        ul {
            text-align: left;
            @include xs {
                text-align: center;
            }
        }

        li {
            float: none;

            @include mdlg {
                height: 60px;
                padding-top: 10px;
            }

            &:last-child {
                background: $gray_lighter;

                @include xssm {
                    background: transparent;
                    height: 50px;
                }

                a {
                    color: $color_brand_secondary;

                    &:hover {
                        color: $color_brand_primary;
                    }
                }
            }
        }
    }
}
