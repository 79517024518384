//fonts
@import "../bootstrapMarketingSB/projet/fonts";

// ######## Import Bootstrap ########
@import "../bootstrapMarketing/bootstrap";

// ######## project ########
// variable core
@import "../bootstrapMarketing/projet/variable";

//variable theme Soir
@import "../bootstrapMarketingSB/projet/variable";

//mixins
@import "../bootstrapMarketing/projet/mixin";

//core
@import "../bootstrapMarketing/core";

// ######## surcharge ########
//projet
@import "../bootstrapMarketingSB/projet/base";

//layout
@import "../bootstrapMarketingSB/layout/structure";
@import "../bootstrapMarketingSB/layout/header";
@import "../bootstrapMarketingSB/layout/kiosque";
@import "../bootstrapMarketingSB/layout/footer";
//
//module
@import "../bootstrapMarketingSB/module/call_center";
@import "../bootstrapMarketingSB/module/forms";
@import "../bootstrapMarketingSB/module/fields";
@import "../bootstrapMarketingSB/module/box_offre";
@import "../bootstrapMarketingSB/module/carousel";
@import "../bootstrapMarketingSB/module/menu_main";
@import "../bootstrapMarketingSB/module/menu_formule";
@import "../bootstrapMarketingSB/module/btn";
@import "../bootstrapMarketingSB/module/slick-theme";
@import "../bootstrapMarketingSB/module/payment";