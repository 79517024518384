.page-faq {
    .main-container {
        margin: auto;
        max-width: $max-width-lg;
        padding: 15px;
    }

    #cm-menu-formule {
        display: none;
    }

    .cm-main-title {
        display: block;
        margin-bottom: 0;

        h1 {
            font-family: roboto_condensedbold, arial, sans-serif;
            margin-bottom: 0;

            @include xs {
                margin-top: 60px;
            }
        }
    }
    #views-exposed-form-recherche-page, .faq-content {
        margin: auto;
        max-width: 1170px;
        padding: 0 24px;
    }
}

.faq-content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 12px;

    @include lg {
        padding: 0;
    }

    a {
        text-decoration: none;
    }
}

.faq-category-indent {
    margin-left: 40px;
}

.faq-qa {
    display: none;
    margin-left: 40px;

    @include xs {
        margin-left: 0;
    }
}

.faq-qa-header {
    cursor: pointer;
    margin: 24px 0;
    padding-left: 24px;
    position: relative;

    &::before {
        align-items: center;
        content: '+';
        display: flex;
        font-size: 24px;
        height: 100%;
        left: 0;
        line-height: 1;
        position: absolute;
        text-align: center;
        top: 0;
        width: auto;

        &.open {
            content: '-';
        }

        h3 {
            font-size: 1.4rem;
        }
    }

    &.is-open {
        + .faq-qa {
            display: block;
        }
    }
}

.is-open {
    &.faq-qa-header {
        &::before {
            content: '-';
            line-height: 24px;
        }
    }
}

.faq-question {
    background: $gray_light;
    border: 1px solid #eee;
    cursor: pointer;
    margin: 12px 0;
    padding: 0 0 0 24px;
    position: relative;

    &::before {
        align-items: center;
        background: $gray_light;
        color: $gray_dark;
        content: '+';
        display: flex;
        font-size: 20px;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 24px;
    }

    a {
        display: block;
        font-weight: bold;
        padding: 7px;
        padding-left: 20px;
    }
}

.faq-qa-visible {
    &.faq-question {
        &::before {
            content: '-';
        }
    }
}

.faq-answer {
    max-width: 800px;
    padding: 10px 10px 10px 60px;

    @include xs {
        margin-left: 20px;
        padding: 10px;
        word-break: break-word;
    }
}

//Search

#views-exposed-form-recherche-page {
    padding: 0;
    
    .views-exposed-widgets {
        align-items: flex-end;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include smmdlg {
            flex-wrap: nowrap;
        }
    }
    label {
        font-size: 1.8rem;
        line-height: 1.2;
        margin-bottom: 24px;
        text-transform: uppercase;
    }
    #edit-search-wrapper {
        max-width: 1200px;
        padding: 0;
        width: 100%;
    }
    .r--search {
        margin-bottom: 0;
        &::before {
            content: '\E01E';
            color: $color_brand_primary;
            font-family: 'icons';
            font-size: 18px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
    #edit-search {
        border: none;
        border-bottom: 2px solid $gray_light;
        color: $color_brand_primary;
        padding: 8px 8px 8px 24px;
        position: relative;
        width: 100%;
        &:focus {
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $gray;
            outline: none;
        }
        &::placeholder {
            color: $gray_light;
        }
    }
    .views-submit-button {
        display: none;
        padding: 0;
        width: 100%;

        @include smmdlg {
            padding: 0 0 0 24px;
            width: auto;
        }
    }
    #edit-submit-recherche {
        margin: auto;
        width: 100%;
    }
}

// Resultats

.view-recherche {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 1170px;
    padding: 0 24px;

    @include lg {
        padding: 0;
    }
    .view-filters {
        order: 1;
        margin-bottom: 12px;
    }
    .view-header {
        order: 2;
        color: $gray_dark;
    }
    .item-list {
        order: 3;
        padding: 0;
        ul {
            list-style: none;
            margin-top: 48px;
            padding-left: 0;
        }
        span {
            display: block;
            font-weight: bold;
            font-size: 1.2rem;
            margin-top: 36px;
        }
        p {
            max-width: none;
        }
    }
}
